import { Token } from '@stripe/stripe-js';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';

import { useStripeHelp } from '../useStripeHelp';
import { ApiResponse } from './types';
import { updateBillingHistoryCache } from './useBillingHistory';
import { updatePartnerCacheById } from './usePartner';

export interface Subscription {
	amount: number;
	quantity: number;
	current_cycle_start_date: string;
	expires: string;
	canceled_at?: string | null;
}

export function useSubscription(partnerId: string): ApiResponse<Subscription> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(`b2b/partners/${partnerId}/subscription`, async endpoint => {
		try {
			const response = await apiRequest({ endpoint });
			const subscription = response.data?.subscription ?? response.data;
			if (!subscription) {
				throw new Error('Not able to fetch subscription details');
			}

			await updateBillingHistoryCache(partnerId);
			return subscription;
		} catch (responseError) {
			dispatch(
				setBannerMessage({
					message: `Failed to retrieve subscription details`,
					isError: true,
					flash: true,
				}),
			);
			throw responseError;
		}
	});

	if (error) {
		return { loading: false, error };
	}

	if (!data) {
		return { loading: true, error: undefined };
	}

	return {
		data,
		error: undefined,
		loading: false,
	};
}

export function useCancelSubscription(partnerId: string): [() => Promise<void>, ApiResponse<Subscription>] {
	const apiRequest = useApi();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	async function cancelSubscription(): Promise<void> {
		try {
			setLoading(true);
			setError(undefined);
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/subscription`,
				method: 'DELETE',
			});
			await mutate(`b2b/partners/${partnerId}/subscription`);
		} catch (err) {
			setError(getCalmErrorOrError(err));
			throw err;
		} finally {
			setLoading(false);
		}
	}
	return [cancelSubscription, { loading, error }];
}

interface ReactivateSubscriptionArgs {
	stripeToken?: Token | undefined;
	stripePaymentMethodId?: string | undefined;
}

export function useReactivateSubscription(): [
	({ name }: { name: string }) => Promise<void>,
	ApiResponse<Subscription> & { hasReactivated: boolean },
] {
	const { partnerId } = useParams();
	const apiRequest = useApi();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [hasReactivated, setHasReactivated] = useState(false);
	const { getPaymentMethodId, getStripeToken } = useStripeHelp();

	async function reactivateSubscription({
		stripeToken,
		stripePaymentMethodId,
	}: ReactivateSubscriptionArgs): Promise<void> {
		try {
			setLoading(true);
			setError(undefined);
			const body = {
				stripe_token: stripeToken,
				stripe_payment_method_id: stripePaymentMethodId,
			};
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/reactivate`,
				method: 'PATCH',
				body,
			});
			await mutate(`b2b/partners/${partnerId}/subscription`);
		} catch (err) {
			setError(getCalmErrorOrError(err));
			throw err;
		} finally {
			setLoading(false);
		}
	}

	const onReactivatePlan = async ({ name }: { name?: string }): Promise<void> => {
		if (loading) {
			return;
		}
		try {
			// only one of these will be set because either the payment element (new) or
			// the card element (old) will be present on the page when this executes
			const paymentMethodId = await getPaymentMethodId();
			const stripeToken = await getStripeToken({ name });

			await reactivateSubscription({ stripeToken, stripePaymentMethodId: paymentMethodId });

			setHasReactivated(true);
		} catch {
			// Any errors are returned as reactivationError from the hook
		}
	};

	return [onReactivatePlan, { loading, error, hasReactivated }];
}

export function useUpdateSubscription(
	partnerId: string,
): [(coveredLives: number) => Promise<void>, { loading: boolean; error: CalmError | Error | undefined }] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	async function update(coveredLives: number): Promise<void> {
		try {
			setLoading(true);
			const body = { covered_lives: coveredLives };
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/subscription`,
				method: 'PATCH',
				body,
			});
			await Promise.all([
				mutate(`b2b/partners/${partnerId}/subscription`),
				updatePartnerCacheById(partnerId),
			]);
		} catch (err) {
			setError(getCalmErrorOrError(err));
			dispatch(
				setBannerMessage({
					message: `Failed to update subscription`,
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	}
	return [update, { loading, error }];
}

export interface TerminateResult {
	metadata: {
		cycle_start_date: string;
		individual_subscriptions_canceled: number;
		individual_subscriptions_expiration_date: string;
		months_into_cycle: number;
		original_amount: number;
	};
	preview_mode: boolean;
}

export function useTerminateSubscription(
	partnerId: string,
): [() => Promise<void>, ApiResponse<TerminateResult>] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<TerminateResult | undefined>(undefined);

	async function terminate(): Promise<void> {
		try {
			setLoading(true);
			const response = await apiRequest({
				endpoint: `b2b/partners/${partnerId}/terminate`,
				method: 'POST',
				params: {
					preview_mode: false,
				},
			});
			setData(response.data);
			await mutate(`b2b/partners/${partnerId}/subscription`);
		} catch (err) {
			setError(getCalmErrorOrError(err));
			dispatch(
				setBannerMessage({
					message: `Failed to terminate subscription`,
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	}
	return [terminate, { loading, error, data }];
}

export function useTerminateSubscriptionPreview(partnerId: string): ApiResponse<TerminateResult> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(`b2b/partners/${partnerId}/terminate`, async endpoint => {
		try {
			const response = await apiRequest({
				endpoint,
				method: 'POST',
				params: {
					preview_mode: true,
				},
			});
			if (!response.data) {
				throw new Error('Not able to fetch subscription terminate preview');
			}
			return response.data;
		} catch (responseError) {
			dispatch(
				setBannerMessage({
					message: `Failed to retrieve subscription terminate preview`,
					isError: true,
					flash: true,
				}),
			);
			throw responseError;
		}
	});

	if (error) {
		return { loading: false, error };
	}

	if (!data) {
		return { loading: true, error: undefined };
	}

	return {
		data,
		error: undefined,
		loading: false,
	};
}
